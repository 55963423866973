<template>
  <div class="grid-column grid-column_span_5 not-found">
    404<br />
    Not Found
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NotFound",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped>
.not-found {
  display: grid;
  place-items: center;
  text-align: center;
  padding-top: val(128);
  padding-bottom: val(140);
  font-size: val(96);
  line-height: 85%;
  @include mobile {
    padding-top: valM(156);
    padding-bottom: valM(156);
    font-size: valM(46);
  }
}
</style>
